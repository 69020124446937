import { createAsyncStackTaggingLink } from "../../../user-agent-fallbacks/async-stack-tagging";
import { AbortedByUserError } from "../aborted-by-user-error";

/**
 * Load a single {@link HTMLImageElement}.
 *
 * The returned promise either rejects with:
 * - {@link AbortedByUserError} when the abort happens on the given signal,
 * - {@link ImageHasNoSizeError} when the image has an unkown (or zero) size,
 * - an instance of {@link Event} when the image fails to load, or
 * - an unknown value.
 */
export const loadSingleImage = async (
  imageElement: HTMLImageElement,
  abortSignal: AbortSignal
) => {
  if (imageElement.complete) {
    return;
  }

  if (!isSizeOfImageElementDefined(imageElement)) {
    throw new ImageHasNoSizeError(
      "Cannot load image elements with unknown (or zero) size. "
    );
  }

  const resetImageProperties = () => {
    imageElement.removeAttribute("fetchPriority");
    imageElement.loading = "lazy"; // eslint-disable-line no-param-reassign
  };

  // We use fetch priority "low" since this image is not as important as other images with loading="eager",
  // since this image isn't shown currently.
  imageElement.setAttribute("fetchPriority", "low");
  imageElement.loading = "eager"; // eslint-disable-line no-param-reassign

  const abortionHandler = () => {
    resetImageProperties();
  };
  abortSignal.addEventListener("abort", abortionHandler);

  await imageLoadingCompleted(imageElement, abortSignal);

  resetImageProperties();
  abortSignal.removeEventListener("abort", abortionHandler);
};

const imageLoadingCompleted = (
  imageElement: HTMLImageElement,
  abortSignal: AbortSignal
) =>
  new Promise<void>((resolve, reject) => {
    const task = createAsyncStackTaggingLink("imageLoadingCompleted");

    const abortListener = () =>
      task(() => {
        imageElement.removeEventListener("load", imageLoadEventListener);
        imageElement.removeEventListener("error", imageErrorEventListener);
        reject(new AbortedByUserError(abortSignal.reason));
      });

    const imageLoadEventListener = () =>
      task(() => {
        abortSignal.removeEventListener("abort", abortListener);
        imageElement.removeEventListener("error", imageErrorEventListener);
        resolve();
      });

    const imageErrorEventListener = (event: Event) =>
      task(() => {
        abortSignal.removeEventListener("abort", abortListener);
        imageElement.removeEventListener("load", imageLoadEventListener);
        reject(event);
      });

    abortSignal.addEventListener(
      "abort",
      abortListener,
      EVENT_HANDLER_RUN_ONCE_OPTION
    );
    imageElement.addEventListener(
      "load",
      imageLoadEventListener,
      EVENT_HANDLER_RUN_ONCE_OPTION
    );
    imageElement.addEventListener(
      "error",
      imageErrorEventListener,
      EVENT_HANDLER_RUN_ONCE_OPTION
    );
  });

const EVENT_HANDLER_RUN_ONCE_OPTION = {
  once: true,
} as const satisfies AddEventListenerOptions;

const isSizeOfImageElementDefined = (imageElement: HTMLImageElement) => {
  const imageBoundingClientRect = imageElement.getBoundingClientRect();
  return (
    imageBoundingClientRect.width > 0 && imageBoundingClientRect.height > 0
  );
};

class ImageHasNoSizeError extends Error {}
